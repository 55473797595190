require('jquery-validation');

$(document).ready(function () {
    $.validator.addMethod("RESTRICTIONS", function(value, element) {
        return this.optional(element) || /^([a-zA-Z0-9!?@£"\-'_.%&*()#/+-,:\s]*)$/i.test(value);
    }, "Due to security reasons, we do not accept semi-colons or brackets in our forms.");

    $.validator.addMethod("CHECKPSW", function(value, element) {
        return /[A-Z]/.test(value) // has a uppercase letter
            && /[a-z]/.test(value) // has a lowercase letter
            && /\d/.test(value) // has a digit
    }, "Password must contain at least one digit, one uppercase and one lowercase letter");

});