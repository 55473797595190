const $ = require('jquery');

require('../../plugins/bootstrap-3.3.7-dist/js/bootstrap.min');
const Routing = require('./components/Routing');
require('./components/Validation');

require('../../plugins/bootstrap-3.3.7-dist/css/bootstrap.css');
require('../../css/style.css');

$(document).on("click", "#btnScrollTop", function(){
    goToByScrollBottom("#section_1");
    $("#sectionNo").val(1);
    $(this).addClass('hidden');
    $("#btnScrollDown").removeClass("hidden");
});

$(document).on("click", "#btnScrollDown", function(){
    let currentSlide = $("#sectionNo").val();
    let lastSlide = $("#totalSectionNo").val();

    if ( currentSlide != lastSlide) {
        let newSlide = parseInt(currentSlide) + 1;
        let elementId = "#section_" + newSlide;
        goToByScrollBottom(elementId);
        $("#sectionNo").val(newSlide);

        if (parseInt(newSlide) == parseInt(lastSlide)) {
            $(this).addClass('hidden');
            $("#btnScrollTop").removeClass("hidden");
        }

    }
});

$(function () {

    validateNewsletterForm("#newsletterForm");

    $("#dropExpertise .angle-icon-dropdown").click(function(){
        $("#filterByTag").focus();
    });

    $.validator.addMethod("RESTRICTIONS", function(value, element) {
        return this.optional(element) || /^([a-zA-Z0-9!?@£"\-'_.%&*()#/+-,:\s]*)$/i.test(value);
    }, "Due to security reasons, we do not accept semi-colons or brackets in our forms.");

    $.validator.addMethod("CHECKPSW", function(value, element) {
        return /[A-Z]/.test(value) // has a uppercase letter
            && /[a-z]/.test(value) // has a lowercase letter
            && /\d/.test(value) // has a digit
    }, "Password must contain at least one digit, one uppercase and one lowercase letter");


    //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
    let MQL = 1170;

    //primary navigation slide-in effect
    if($(window).width() > MQL) {
        let headerHeight = $('.cd-header').height();
        $(window).on('scroll',
            {
                previousTop: 0
            },
            function () {
                let currentTop = $(window).scrollTop();
                //check if user is scrolling up
                if (currentTop < this.previousTop ) {
                    //if scrolling up...
                    if (currentTop > 0 && $('.cd-header').hasClass('is-fixed')) {
                        $('.cd-header').addClass('is-visible');
                    } else {
                        $('.cd-header').removeClass('is-visible is-fixed');
                    }
                } else {
                    //if scrolling down...
                    $('.cd-header').removeClass('is-visible');
                    if( currentTop > headerHeight && !$('.cd-header').hasClass('is-fixed')) $('.cd-header').addClass('is-fixed');
                }
                this.previousTop = currentTop;
            });
    }

    //open/close primary navigation
    $('.cd-primary-nav-trigger').on('click', function(){
        $('.cd-menu-icon').toggleClass('is-clicked');
        $('.cd-header').toggleClass('menu-is-open');

        //in firefox transitions break when parent overflow is changed, so we need to wait for the end of the trasition to give the body an overflow hidden
        if( $('.cd-primary-nav').hasClass('is-visible') ) {
            $('.cd-primary-nav').removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').removeClass('overflow-hidden');
            });
        } else {
            $('.cd-primary-nav').addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').addClass('overflow-hidden');
            });
        }
    });

    (function (container) {
        let $container = $(container);
        $container.on('click', 'button.accept', function () {
            let cookieName = $(this).data('cookie-name');
            if (!cookieName) {
                return;
            }

            setCookie(cookieName, new Date(), 365);

            $container.fadeOut(500, function () {
                $container.remove();
            });
        })
    })('#cookiePolicyPopup');
});

function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toGMTString();
    document.cookie = cname + '=' + cvalue + '; ' + expires + "; path=/";
}

function validateNewsletterForm(id) {
    $(id).validate({
        rules: {
            'newsletter[email]': {
                required: true,
                email: true
            },
            'newsletter[name]': {
                required: true
            }
        },
        messages: {
            'newsletter[email]': {
                required: "Email required",
                email: "Invalid email address"
            },
            'newsletter[name]': {
                required: "Name required"
            }
        },

        submitHandler: function(form) {
            $.ajax({
                type: "POST",
                url: Routing.generate('app_web_newsletter_form'),
                data: $("#newsletterForm").serialize(),
                dataType: 'json',
                success: function (resp) {
                    let html = '';
                    if (resp.success) {
                        html = '<div class="col-md-12 col-sm-12 col-xs-12"> <div class="alert alert-success">'+ resp.message +
                            '</div></div>';
                        $("#newsletterContainer").html(html);
                    } else {
                        $("#newsletterError").remove();
                        html = '<div class="col-md-12 col-sm-12 col-xs-12" id="newsletterError"><div class="alert alert-danger">'+ resp.message +
                            '</div></div>';
                        $("#newsletterContainer").prepend(html);
                    }

                }
            });
        }

    });
}

$(document).on('click', '.js-togle-sugmenu', function () {
    toggleSubmenu($(this));
});

function toggleSubmenu(button) {
    let open  = button.attr('data-open');
    if ( open == 0 ) {
        $("#menuArrow").html('<i class="fa fa-angle-up" aria-hidden="true"></i>');
        button.attr('data-open', 1);
        $("#subMenu").show();
    } else {
        $("#menuArrow").html('<i class="fa fa-angle-down" aria-hidden="true"></i>');
        button.attr('data-open', 0);
        $("#subMenu").hide();
    }
}

function goToByScrollBottom(id){

    $('html,body').animate({scrollTop: $(id).offset().top }, 700);
}

$(document).on("click", "#benefits-btn-mobile", function(){
    $("#menu-drop-mini-list").toggle();
});

let calculatePositionToTop = (start, end) => {
    for(let i = start; i <= end; i++) {
        let topTop =  $("#section_" + i).offset().top;
        $("#topTop_" + i).val(parseInt(topTop));
    }
};

export default { calculatePositionToTop, setCookie, goToByScrollBottom };
